<template>
<loader v-bind="{ loading }" text="Loading Teams">
  <columns>
    <column>
      <page-heading
          heading="Teams"
      />
    </column>
    <column class="is-5">
      <form>
        <text-input 
          v-model="filters.search"
          classes="has-addons is-rounded"
          placeholder="Search teams...">
          <template #right>
            <div class="buttons has-addons is-rounded">
              <submit-button 
                @submit="runSearch"
                class="is-rounded">
                Search
              </submit-button>
              <action-button
                v-if="$route.query.search"
                @click="clearFilters">
                <icon icon="times"/> 
              </action-button>
              <router-link 
                class="button is-rounded is-flex" 
                :to="{
                  name: 'create-team'
                }">
                  <icon icon="plus"/>
                  <span>Team</span>
                </router-link>
            </div>
          </template>
        </text-input>
      </form>
    </column>
  </columns>
  <columns>
    <column>
      <div class="index-rows">
        <div class="box is-marginless" v-for="team in teams.data" :key="team.id">
          <columns class="is-mobile">
            <column class="is-half-mobile">
              <router-link class="mr-2" :to="{
                name: 'team-manager',
                params: {
                  team: team.uuid
                }
              }">
                {{ team.name }}
              </router-link>
              <br>
              <small class="has-text-grey">Lead By {{ team.leader.full_name }}, Updated {{ team.updated_at | asCalendar }}</small>
            </column>
            <column class="is-one-fifth-tablet is-two-quarters-mobile is-flex is-align-items-center is-justify-content-end">
              <index-info 
                label="Branch" 
                :info="team.branch.name"
              />
            </column>
            <column class="is-one-fifth-tablet is-two-quarters-mobile is-align-items-center is-justify-content-end">
              <index-info
                icon="street-view" 
                label="Users" 
                :info="team.users_count"
              />
            </column>
          </columns>
        </div>
      </div>
      <pager v-if="teams.data.length" :pageable="teams" context="Team" jump-controls @nav="goToPage" />

      <no-items-to-display
        :item-count="teams.data.length"
        with-link
        heading="There are no teams available for display."
        :to="{
          name: 'create-team'
        }"
        to-text="New Team"
      />

    </column>
  </columns>
</loader>
</template>
<script>
import { get } from '@/api/request'
export default {

  data: () => ({
    loading: true,
    filters: {
      search: ''
    },
    teams: {
      data: []
    }
  }),

  created() {
    if(this.$route.query) {
      this.filters.search = this.$route.query.search
    }
    this.loadTeams()
  },

  methods: {
    loadTeams() {
      get('v1' + this.$route.fullPath, ({data}) => {
        this.teams = data
        this.loading = false
      }, () => {
        this.loading = false
      }, { params: this.filters })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    runSearch() {
      this.$router.push({
        name: 'teams',
        query: this.filters
      });
    },
    clearFilters() {
      this.filters.search = ''
      this.$router.push({
        name: 'teams',
      });
    }
  },

  watch: {
    '$route': 'loadTeams'
  }

}
</script>